.mat-mdc-table {
  th,
  td {
    @apply font-sans;
    @apply py-3;
  }
  th {
    @apply font-bold;
  }
}

:root {
  .mat-mdc-button,
  .mat-mdc-outlined-button,
  .mat-mdc-unelevated-button,
  .mat-mdc-raised-button {
    @apply rounded-button min-w-[6rem] min-h-[3rem] font-bold;
    box-shadow: none!important;

    &.square-button {
      min-width: 3rem;
      mat-icon {
        margin: 0;
      }
    }
    &.compact-button {
      min-height: 2.5rem;
      min-width: 2.5rem;
      padding: 0 10px;
    }
  }

  .mat-mdc-fab,
  .mat-mdc-mini-fab {
    box-shadow: none!important;
  }



  .mat-button-toggle-group:not(.mat-button-toggle-vertical) {
    @apply rounded-button;

    .mat-button-toggle:first-child .mat-button-toggle-label-content {
      @apply pl-4;
    }

    .mat-button-toggle:last-child .mat-button-toggle-label-content {
      @apply pr-4;
    }
  }

  .mat-button-toggle-button {
    @apply font-medium;
  }

  .mat-mdc-icon-button {
    .mat-icon {
      @apply inline-flex items-center justify-center;
    }
  }
}

.button-sm {

  .mat-mdc-button,
  .mat-mdc-outlined-button,
  .mat-mdc-unelevated-button,
  .mat-mdc-raised-button {
    @apply rounded-button min-w-[1rem] min-h-[2.4rem] font-bold text-center;

    &.square-button {
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 2.4rem;
      width: 2.4rem;
      box-sizing: border-box;

      .mat-icon {
        width: auto;
        height: auto;
        margin: 0;
        padding: 0;
      }
    }
  }
}

a.rms-link {
  @apply text-ruber;
  transition: color 0.3s ease-in-out;
  &:hover {
    @apply text-ruberdark;
    transition: color 0.3s ease-in-out;
  }
  &:active {
    @apply text-ruberdarker;
    transition: color 0.3s ease-in-out;
  }
}

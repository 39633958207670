.rms-badge {
  @apply inline-flex items-center text-center rounded-sm font-bold;
  background-color: #F5F1F1;
  color: #11100f;

  &_sm {
    @apply text-sm px-1 py-0.5;
  }

  &_md {
    @apply text-base px-1.5 py-1;
  }

  &_lg {
    @apply text-lg px-1.5 py-1;
  }p

  &.black {
    @apply bg-black text-white;
  }

  &.ruber, &.danger {
    @apply bg-ruber text-white;
  }

  &.success, &.viridis  {
    @apply bg-viridis text-atrovirens;
  }

  &.warning, &.ochraceus, {
    @apply bg-ochraceus text-fulvus;
  }

  &.info, &.caeruleus{
    @apply bg-caeruleus text-cyaneus
  }


}

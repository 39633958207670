:root {
  --mat-switch-handle-margin: 3px;
  /* Single margin variable for all states */

  .mdc-switch .mdc-switch__track {
    @apply rounded-[2000px] h-6 overflow-hidden relative w-[41.14px];
  }

  .mdc-switch:enabled .mdc-switch__shadow {
    @apply bg-white;
  }

  .mdc-switch__track::after,
  .mdc-switch:enabled .mdc-switch__track::after,
  .mdc-switch:enabled:focus:not(:active) .mdc-switch__track::after,
  .mdc-switch:enabled:hover:not(:focus):not(:active) .mdc-switch__track::after {
    @apply bg-ruber;
  }

  .mat-mdc-slide-toggle .mdc-switch--selected .mdc-switch__handle,
  .mat-mdc-slide-toggle .mdc-switch--selected:active:not(.mdc-switch--disabled) .mdc-switch__handle {
    @apply w-[17px] h-[17px] bg-white;
  }

  .mat-mdc-slide-toggle .mdc-switch--unselected .mdc-switch__handle,
  .mat-mdc-slide-toggle .mdc-switch--unselected:active:not(.mdc-switch--disabled) .mdc-switch__handle {
    @apply w-[17px] h-[17px] m-0 bg-white ml-[var(--mat-switch-handle-margin)];
  }
}

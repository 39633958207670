:root {
  .mat-mdc-dialog-component-host {
    @apply block max-h-[90vh];
  }

  .mat-mdc-dialog-container .mdc-dialog__surface {
    @apply rounded-md;
  }

  .mat-mdc-dialog-title {
    @apply pt-3;
  }

  /** Reset default height placeholder */
  .mdc-dialog__title::before {
    content: none;
    height: auto;
  }
}

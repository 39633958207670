:root {
  .mat-mdc-card {
    @apply rounded border;
    border-color: rgba(0, 0, 0, 0.24);
    box-shadow: none;
    &:hover {
      border-color: rgba(0, 0, 0, 0.32);
    }
    &:active {
      border-color: rgba(0, 0, 0, 0.40);
    }
  }
}

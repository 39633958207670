:root {
  .mdc-checkbox__background {
    border: 1px solid rgba(0, 0, 0, 0.24) !important;
    box-sizing: border-box !important;
  }
  .mdc-checkbox {
    margin: 0px 0px auto !important;
  }
  .checkbox-lg {
    align-items: start !important;
    border-radius: 0.125rem !important;
  }
  .checkbox-md {
    border-radius: 0.25rem !important;
  }
  .checkbox-sm {
    .mdc-checkbox__background {
      padding: 1px !important;
      border-radius: 0.375rem !important;
    }
    .mat-checkbox .mat-checkbox-layout {
      display: flex !important;
      align-items: flex-start !important;
    }
    .mat-checkbox .mat-checkbox-inner-container {
      margin-top: 0 !important;
    }
  }
}

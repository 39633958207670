:root {
  .mention {
    @apply rms-badge rms-badge_sm;
  }

  .ql-mention-list-container {
    @apply bg-white rounded shadow-lg border border-gray-200 p-2 z-50 w-52;
  }

  .ql-mention-list {
    @apply list-none overflow-hidden;

    &-item {
      @apply flex items-center my-1 rounded text-sm font-bold h-10 transition duration-100 ease-out min-h-10 px-2 cursor-pointer;

      &:first-child {
        @apply mt-0;
      }

      &:last-child {
        @apply mb-0;
      }

      //i.e. hover
      &.selected {
        @apply bg-primary-600/10;
      }

      &.disabled {
        @apply cursor-auto;
      }
    }
  }

  /*
    quill doesn't have a class for the container.
    so we use the component selector
  */
  quill-editor {
    @apply flex flex-col w-full h-full;
  }

  .ql-toolbar,
  .ql-container {
    @apply bg-white border border-gray-200;
  }

  .ql-toolbar {
    @apply p-4 #{!important};
    @apply rounded-t-lg;

    button {
      @apply h-7 w-7 p-0 flex #{!important};
      @apply items-center justify-center rounded-md text-black;
    }

    .mat-mdc-button-persistent-ripple {
      @apply rounded-md;
    }

    .mat-mdc-form-field-subscript-wrapper {
      display: none;
    }

    .mat-mdc-form-field-infix {
      @apply min-h-7 h-7 flex items-center;
    }
  }

  .ql-container {
    @apply rounded-b-lg;

    /*
      We need this hack with position: absolute because of the .ql-editor height issue.
      When we add width and height styles to quill-editor,
      the .ql-editor has a height of 100% + toolbar height.

      Or do height: calc(100% - toolbar.height). But this is not flexible.
    */
    .ql-editor {
      @apply absolute top-0 left-0 py-2 w-full;

      p,
      ol,
      ul,
      blockquote {
        @apply text-sm leading-7;
      }

      ol,
      ul,
      blockquote {
        @apply font-sans;
      }

      h1 {
        @apply text-3xl;
      }

      h2 {
        @apply text-2xl;
      }

      h3 {
        @apply text-xl;
      }

      // placeholder
      &.ql-blank::before {
        @apply font-sans text-sm text-niger/60 not-italic leading-7;
      }
    }
  }
}

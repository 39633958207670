:root {
  .mat-mdc-card {
    @apply rounded border;
    border-color: rgba(0, 0, 0, 0.24);
    box-shadow: none;
    &:hover {
      border-color: rgba(0, 0, 0, 0.32);
    }
    &:active {
      border-color: rgba(0, 0, 0, 0.4);
    }
  }
  .checklist-card {
    @apply px-6 py-4 border-none rounded-none;

    transition:
      background-color 0.3s,
      border-color 0.3s;
    .mat-mdc-card {
      border-color: rgba(0, 0, 0, 0.24);
      box-shadow: none;
    }
    &:hover {
      @apply bg-hover;
    }
  }
}

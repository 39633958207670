:root {
  .mat-button-base {
    @apply rounded-button font-bold;
    box-shadow: none !important;
  }

  .mat-mdc-button,
  .mat-mdc-outlined-button,
  .mat-mdc-unelevated-button,
  .mat-mdc-raised-button {
    @apply min-w-[6rem] min-h-[3rem];
    @extend .mat-button-base;

    &.square-button {
      min-width: 3rem;
      mat-icon {
        margin: 0;
      }
    }

    &.compact-button {
      @apply min-w-[2.5rem] min-h-[2.5rem] px-[10px];
    }

    /* Small button variant */
    &.button-sm {
      @apply min-w-[1rem] min-h-[2.4rem] text-center;

      &.square-button {
        @apply p-0 flex items-center justify-center h-[2.4rem] w-[2.4rem] box-border;
        .mat-icon {
          @apply w-auto h-auto m-0 p-0;
        }
      }
    }
  }

  .mat-mdc-fab,
  .mat-mdc-mini-fab {
    box-shadow: none !important;
  }

  .mat-button-toggle-group:not(.mat-button-toggle-vertical) {
    @apply rounded-button;
    .mat-button-toggle:first-child .mat-button-toggle-label-content {
      @apply pl-4;
    }
    .mat-button-toggle:last-child .mat-button-toggle-label-content {
      @apply pr-4;
    }
  }

  .mat-button-toggle-button {
    @apply font-medium h-full;
    text-wrap: auto;
  }

  .mat-mdc-icon-button {
    &.square-icon-button {
      @apply min-w-[2.5rem] min-h-[2.5rem] px-[10px] contents items-center justify-center;
      mat-icon {
        @apply m-0 flex items-center justify-center;
      }
    }
    .mat-icon {
      @apply inline-flex items-center justify-center;
    }
  }
}

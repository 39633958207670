.vex-layout-romulus {
  --vex-border-radius: 12px;
  --vex-sidenav-background: #f5f1f0;

  --vex-sidenav-item-min-height: 40px;

  --vex-sidenav-color: theme("colors.black");
  // Sidenav
  --vex-sidenav-toolbar-background: var(--vex-sidenav-background);
  // Item
  --vex-sidenav-item-color: theme("colors.black");
  --vex-sidenav-item-color-hover: theme("colors.black");
  --vex-sidenav-item-color-active: theme("colors.black");

  --vex-sidenav-item-background-hover: #ece4e2;
  --vex-sidenav-item-background-active: #e1d7d4;

  --vex-sidenav-item-padding-vertical: theme("space.0");
  --vex-sidenav-item-padding-horizontal: theme("space.3");
  --vex-sidenav-item-margin-vertical: theme("space.1");
  --vex-sidenav-item-margin-horizontal: theme("space.3");

  // Icon
  --vex-sidenav-item-icon-color: theme("colors.black");
  --vex-sidenav-item-icon-color-hover: theme("colors.black");
  --vex-sidenav-item-icon-color-active: theme("colors.black");

  // Toolbar
  --vex-toolbar-background: var(--vex-sidenav-background);
  --vex-toolbar-icon-color: theme("colors.black");
}

@use "@angular/material" as mat;

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .form-sm {
    $density: -5;
    @include mat.private-form-field-density($density);
    @include mat.autocomplete-density($density);
    @include mat.select-density($density);
    @include mat.input-density($density);
    @include mat.all-component-densities($density);
    @include mat.checkbox-density($density);

 
  }

  .form-md {
    $density: -2;
    @include mat.private-form-field-density($density);
    @include mat.autocomplete-density($density);
    @include mat.select-density($density);
    @include mat.input-density($density);
  }

  .form-lg {
    $density: 0;
    @include mat.private-form-field-density($density);
    @include mat.autocomplete-density($density);
    @include mat.select-density($density);
    @include mat.input-density($density);
  }
}

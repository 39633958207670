.mdc-notched-outline__notch {
  border-right: none;
}

:root {
  label {
    @apply font-semibold;
  }
  .mdc-text-field--outlined {
    --mdc-outlined-text-field-container-shape: theme("borderRadius.DEFAULT");
  }
  .mat-mdc-form-field-error {
    @apply block text-xs text-ruber;
  }
  .mat-mdc-form-field-hint {
    @apply block text-xs text-black opacity-[0.48];
  }

  .mat-mdc-form-field-hint-wrapper,
  .mat-mdc-form-field-error-wrapper {
    @apply px-0;
  }

  .mdc-text-field {
    &.mdc-text-field--focused {
      .mdc-notched-outline {
        .mdc-notched-outline__trailing {
          box-shadow:
            3px 0 0 #ffad6d,
            /* Right side shadow */ -3px 0 0px transparent,
            /* Left side shadow (removed) */ 0 3px 0px #ffad6d,
            /* Bottom side shadow */ 0 -3px 0px #ffad6d; /* Top side shadow */
        }
        .mdc-notched-outline__leading {
          box-shadow:
            -3px 0 0px transparent,
            /* Right side shadow */ -3px 0 0px #ffad6d,
            /* Left side shadow (removed) */ 0 3px 0px #ffad6d,
            /* Bottom side shadow */ 0 -3px 0px #ffad6d; /* Top side shadow */
        }
        /* Apply shadow to the notched outline */
      }
    }
  }
}

.mdc-text-field::after {
  .mdc-notched-outline > * {
    border-color: rgba(0, 0, 0, 0.2) !important; /* Set default border color */
  }

  &.mdc-text-field--focused .mdc-notched-outline > * {
    border-color: black !important; /* Ensure border stays black on focus */
  }
}

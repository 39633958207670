:root {
  .mat-expansion-panel:not([class*="mat-elevation-z"]) {
    @apply rounded border shadow-none;
    border-color: rgba(0, 0, 0, 0.24);
    &:hover {
      border-color: rgba(0, 0, 0, 0.32);
    }
    &:active {
      border-color: rgba(0, 0, 0, 0.40);
    }
  }
}

// Regular
@font-face {
  font-family: "Bianco Serif";
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url("/assets/fonts/BiancoSerif-Regular.woff") format("woff");
  font-named-instance: "Regular";
}

@font-face {
  font-family: "Bianco Serif";
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url("/assets/fonts/BiancoSerif-Regular.woff") format("woff");
  font-named-instance: "Regular";
}
@font-face {
  font-family: "Bianco Serif";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("/assets/fonts/BiancoSerif-Regular.woff") format("woff");
  font-named-instance: "Regular";
}
@font-face {
  font-family: "Bianco Serif";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("/assets/fonts/BiancoSerif-Regular.woff") format("woff");
  font-named-instance: "Regular";
}

// Bold
@font-face {
  font-family: "Bianco Serif";
  font-style: bold;
  font-weight: 500;
  font-display: swap;
  src: url("/assets/fonts/BiancoSerif-Bold.woff") format("woff");
  font-named-instance: "Bold";
}

@font-face {
  font-family: "Bianco Serif";
  font-style: bold;
  font-weight: 600;
  font-display: swap;
  src: url("/assets/fonts/BiancoSerif-Bold.woff") format("woff");
  font-named-instance: "Bold";
}

@font-face {
  font-family: "Bianco Serif";
  font-style: bold;
  font-weight: 700;
  font-display: swap;
  src: url("/assets/fonts/BiancoSerif-Bold.woff") format("woff");
  font-named-instance: "Bold";
}

@font-face {
  font-family: "Bianco Serif";
  font-style: bold;
  font-weight: 800;
  font-display: swap;
  src: url("/assets/fonts/BiancoSerif-Bold.woff") format("woff");
  font-named-instance: "Bold";
}

@font-face {
  font-family: "Bianco Serif";
  font-style: bold;
  font-weight: 900;
  font-display: swap;
  src: url("/assets/fonts/BiancoSerif-Bold.woff") format("woff");
  font-named-instance: "Bold";
}

:root {
  .mat-mdc-paginator-container {
    @apply text-black;

    .mat-mdc-icon-button {
      @apply rounded-button border border-solid border-black/40;

      &:not(:last-child) {
        @apply mr-2;
      }

      .mat-mdc-button-persistent-ripple {
        @apply rounded-button;
      }

      .mat-mdc-paginator-icon {
        @apply fill-black;
      }

      &:disabled {
        @apply border-black/20;

        .mat-mdc-paginator-icon {
          @apply fill-black/20;
        }
      }
    }

    .mat-mdc-paginator-range-label {
      @apply m-0 w-24;
    }
  }
}
